import { Header } from '@/components/Elements';
import GooglePlay from '@/assets/images/app_stores/google_play.svg'
import AppStore from '@/assets/images/app_stores/app_store.svg'
import PaymentSuccessSvg from '@/assets/images/common/success_tick.svg'
import { Helmet } from 'react-helmet-async';
import { useEffect } from "react";
import ReactGA from "react-ga4";

export function FreeTrailStatus() {

    useEffect(() => {
        gtagReportConversion();
    }, []);

    useEffect(() => {
        console.log('FreeTrial started ========');
        const script = document.createElement("script");
        script.async = true;
        script.innerHTML = `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '420663637498592');
      fbq('track', 'PageView');
      fbq('track', 'Lead');
    `;
        document.head.appendChild(script);

        // Inject the noscript tag for browsers with JavaScript disabled
        const noscript = document.createElement("noscript");
        noscript.innerHTML = `
      <img height="1" width="1" style="display:none"
      src="https://www.facebook.com/tr?id=420663637498592&ev=PageView&noscript=1" />
    `;
        document.body.appendChild(noscript);
        console.log('FreeTrial end ========');


        // Cleanup when the component is unmounted
        return () => {
            if (script) document.head.removeChild(script);
            if (noscript) document.body.removeChild(noscript);
        };
    }, []);


    const gtagReportConversion = () => {
        ReactGA.event("conversion", {
            send_to: 'AW-16786036394/q6TYCLyXnu8ZEKqtmsQ-', // Use the full Conversion ID here
            event_callback: () => {
                console.log('Conversion event reported successfully!');
            },
        });
    };

    return (
        <section className='bg-light vh-100' >
            <Header backNav={false} />
            <Helmet>
                <title>Payment status</title>
            </Helmet>
            <br />
            <div className="container">
                <div className="row">
                    <div className="col-12 d-flex flex-column align-items-center text-center">
                        <div className="container bg-white p-3 py-4 rounded-3">
                            <img src={PaymentSuccessSvg} alt="Success" />
                            <h3 className='text-green' style={{ fontSize: '28px' }} >Request Submitted</h3>
                            <p className="fs-16" style={{ color: "#706C6C" }} >You will receive a call from our team in 30 mins</p>
                            <br />
                            <p className="fs-28 mt-5">Trusted by 50,000+ Investors</p>
                            <div className="d-flex align-content-center justify-content-center mt-3">
                                <a href="https://play.google.com/store/apps/details?id=com.OpiGo1final">
                                    <img src={GooglePlay} alt="GooglePlay" />
                                </a>
                                <div className="mx-2"></div>
                                <a href="https://apps.apple.com/in/app/opigo/id1619955231">
                                    <img src={AppStore} alt="AppStore" />
                                </a>
                            </div>
                            <br />
                            <p className="fs-16 text-warning">Download our app for a better experience</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
