import { usePaymentFlowStore } from '../stores/payment_flow';
import { subscribeDeck } from '../api/subscribeDeck';
import { useState } from 'react';
import { getUtmMedium } from '@/utils/utility';

export function PaymentStep({ channel_id, channel_package_id, couponDiscount}: { channel_id?: string, channel_package_id?: string, couponDiscount: any }) {
  const { token } = usePaymentFlowStore()
  const [isLoading, setIsLoading] = useState(false)
  const utmData = getUtmMedium();
  const isQueryParams:any = window.location.hostname?.split('.')[0];
  const headerParams:any = isQueryParams === 'opigo' ? 'opigo' : isQueryParams === 'kapil' ? 'kapil' : 'web'

  const initiatePayment = async () => {
    setIsLoading(true)
    const result = await subscribeDeck({
      channel_id: channel_id!,
      channel_package_id: channel_package_id!,
      device_os: "web",
      source: "web",
      discounted_amount: couponDiscount?.discount_details?.discounted_amount,
      discount_coupon: couponDiscount?.couponCode,
      final_amount_paid: couponDiscount?.discount_details?.final_amount,
      utm: String(utmData)
    }, token!, headerParams);
    const redirectUrl = result?.payments?.data?.instrumentResponse?.redirectInfo?.url
    if (redirectUrl != undefined) {
      window.location.href = redirectUrl
    }
    setIsLoading(false)
  }

  return (
    <div className='container bg-white p-3 p-md-0 mt-md-4'>
      <div className="my-2 col-12 col-md-6 d-grid">
        <button className={`btn btn-${token ? 'success' : 'disabled'} rounded-2 p-2`} type='button' disabled={isLoading || !token} onClick={initiatePayment} >
          {isLoading ? 'Loading...' : 'Pay Now'}
        </button>
      </div>
      <p className="fs-16 text-light">By proceeding you agree to our <a target={"_blank"} href='/terms_of_use' className='text-green' >Terms of use</a></p>
    </div>
  );
}

