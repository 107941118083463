import React from 'react'
import ColorFlare from '../../assets/images/common/purple-flare.webp'
import DOMPurify from 'dompurify'
import { useNavigate, useParams } from 'react-router-dom'

const NewExploreDeckCard = ({ deck, selectedTime, utmData }: any) => {

    const { coupon } = useParams();
    const navigate = useNavigate();
    const filterData = deck?.channel_packages[0];

    const handleSubscribe = () => {
        if (coupon) {
            return navigate(`/decks/purchase/${deck.id}/${filterData.id}?${utmData}coupon=${coupon}`, { state: { isNewDeck: true } });
        }
        navigate(`/decks/purchase/${deck.id}/${filterData.id}?${utmData}`, { state: { isNewDeck: true } });
    }

    const getPricingInfo = () => {
        if (filterData?.plan_type) {
            return `For ${filterData?.plan_type}`
        } else {
            return `For ${filterData?.num_of_cards} Stock Picks`
        }
    }

    return (
        <div className='position-relative my-4 mx-3 mx-md-0'>
            <img src={ColorFlare} className='color-flare' draggable={false} />

            <div className='explore-deck-card row'>
                <div className='col-12 col-md-5 d-flex flex-column justify-content-between'>

                    <div className='d-flex align-items-center justify-content-between'>
                        <div style={{flexDirection: 'column'}}>
                            <h2 className='deck-name'>{deck.title}</h2>
                            <div className='d-flex align-items-center mt-0 mt-md-2'>
                                <p className='deck-category'>{deck.short_description ? deck.short_description : '---'}</p>
                            </div>
                        </div>

                        {/* lowest price */}
                        <div className={'pricing-container text-center'}>
                            <p className='fs-6 fw-medium'>Starts at</p>
                            <p className='text-white fs-5 fw-semibold'>&#8377; {deck?.minPrice}</p>
                        </div>
                    </div>
                        {/*<p className='deck-price mt-0'>&#8377;{filterData?.price}<span className='deck-price-details'></span></p>*/}
                        {/*<p className='deck-price mt-0'><span className='deck-price-details'>{getPricingInfo()}</span></p>*/}

                    <div>
                        <div className='d-flex justify-content-between mt-md-2'>
                            <div className='d-flex align-items-center' >
                                <div className='deck-returns target-reward-text ms-sm-1'>
                                    {deck?.target_range ? `${deck?.target_range}` : '---'}
                                    <div className='target-reward-sub-text'>
                                        {'Target Range'}
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex align-items-center'>
                                <div className='deck-returns target-reward-text ms-sm-1 ms-2'>
                                    {deck?.time_horizon ? `${deck?.time_horizon}` : '---'}
                                    <div className='target-reward-sub-text'>
                                        {'Time Frame'}
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex align-items-center'>
                                <div className='deck-returns target-reward-text ms-sm-1 ms-2'>
                                    {deck?.risk && deck?.reward
                                      ? `${deck?.risk}:${deck?.reward}`
                                      : '---'}
                                    <div className='target-reward-sub-text'>
                                        {'Risk-Reward'}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <>
                            {/*<button className="subscribe-btn mt-4 float-end d-sm-none" onClick={handleSubscribe}>*/}
                            {/*    Subscribe Now*/}
                            {/*</button>*/}

                            <div className="d-flex justify-content-center d-sm-none mt-2">
                                <button className="subscribe-btn" onClick={handleSubscribe}>
                                    Subscribe Now
                                </button>
                            </div>
                            <button className="subscribe-btn mt-4 d-none d-sm-block" onClick={handleSubscribe}>
                                Subscribe Now
                            </button>
                        </>
                    </div>
                </div>

                <div className='col-12 col-md-7'>
                    <div className='d-flex' style={{ height: '100%' }}>
                        <div className='spacer' />
                        <div>
                            <hr className='my-4 d-block d-md-none' />
                            <div className="deck-info" dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(deck.description)
                            }} >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewExploreDeckCard
