import {useEffect, useState} from "react";
import '../../../styles/freeTrial.scss'
import { B2CLayout } from '@/components/Layout'
import blueCheckMark from '../../../assets/lottie_jsons/blue-checkmark.gif'
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {getUtmMedium} from "@/utils/utility";

const initData = {
    fullname: '',
    mobile: '',
    utmData: '',
    timestamp: '',

}

export const FreeTrial = () => {
    const navigate = useNavigate();
    const [data, setData] = useState(initData);
    const [err, setErr] = useState('');
    const utmData = getUtmMedium();
    const [success, setSuccess] = useState(false);
    const [apiCallLoader, setApiCallLoader] = useState(false);

    const onChangeData = (name: string, e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setErr('');
        setSuccess(false);
        setData({
            ...data,
            [name]: e.target.value
        });
    }

    const handleValidation = () => {
        const phoneNumberRegex = /^[6-9]\d{9}$/;

        const { fullname, mobile } = data;

        if (!fullname || !mobile) {
            setErr('Please fill up all the information.');
            return false;
        }

        if (!phoneNumberRegex.test(mobile)) {
            setErr('Please enter a valid 10-digit WhatsApp number.');
            return false;
        }

        return true;
    }

    const handleSubmitForm = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const isValidData = handleValidation();
        if (isValidData) {
            setApiCallLoader(true);
            setSuccess(false);

            const client = axios.create();

            // Get current time
            const now = new Date();

            // Add IST offset (5 hours 30 minutes) to UTC time
            const istOffset = 5.5 * 60 * 60 * 1000; // 5 hours 30 minutes in milliseconds
            const istTime = new Date(now.getTime() + istOffset);

            // Format the IST time (standard date-time format in IST)
            const formattedISTTime = istTime.toISOString().replace("Z", "+05:30")
            const enrichedData = {
                ...data,
                utmData: utmData,
                timestamp: formattedISTTime,
            };

            try {
                const url = "https://script.google.com/macros/s/AKfycbxlnTClRxSIH-iELWUYxskf86R4Z47u8coTfYbV8ORBxuP66EUTOsl8EgJDqRPnwUnoQg/exec";

                const response = await client.post(url, null, {
                    params: enrichedData,
                });

                if (response.data.error) {
                    setErr(response.data.error);
                } else if (response.data.msg) {
                    setSuccess(true);
                    setData(initData);
                    navigate('/freeTrial/freeTrailStatus');

                    setTimeout(() => {
                        setSuccess(false);
                    }, 3000);
                }
                setApiCallLoader(false);
            } catch (error) {
                setErr("Failed to submit the form. Please try again later.");
                setApiCallLoader(false);
            }
        }
    };


    const sanitizeInput = (value: string) => value.replace(/\D/g, '').slice(0, 10); // Remove non-digits and limit to 10 characters


    return (
        <B2CLayout>
            <div className="d-flex flex-column justify-content-center align-items-center container">
                <div className="mt-3">
                    <img src={blueCheckMark} alt="Cool GIF" />
                </div>

                <h1 className='text-dark mt-2 text-center title'>Let's Build Your Second Income From Stocks</h1>
                <h4 className='description open-sans mt-0 mt-md-2 mb-0 mb-md-5'>
                    Our experts delivered 84% annualized returns  in 2024 🎯 Plans start at just ₹699 🚀
                </h4>

                <div className='form-container'>
                    <form className="form" onSubmit={handleSubmitForm}>
                        <div className='row'>
                            <div className="col-12 col-md-6 input-container">
                                <label htmlFor="fullname">Full Name</label>
                                <input
                                    id="fullname"
                                    placeholder="Enter your full Name"
                                    value={data.fullname}
                                    onChange={(e) => { onChangeData('fullname', e) }}
                                />
                            </div>

                            <div className="col-12 col-md-6 input-container">
                                <label htmlFor="mobile">WhatsApp Number</label>
                                <input
                                    id="mobile"
                                    type="text"
                                    placeholder="Enter 10-digit number"
                                    value={data.mobile}
                                    onChange={(e) => {
                                        const sanitizedValue = sanitizeInput(e.target.value);
                                        onChangeData('mobile', { ...e, target: { ...e.target, value: sanitizedValue } });
                                    }}
                                />
                            </div>
                        </div>

                        <div className='row'>
                            <div className="col-12">
                                <button className="submit-btn cursor-pointer" type="submit">{apiCallLoader ? 'Loading...' : 'Get a Callback'}</button>
                                {err ? <p className='success-text text-danger'> {err} </p> : null}
                                {success ? <p className='success-text text-success'>Thank you! Your information has been submitted successfully.</p> : null}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div style={{ marginBottom: '98px' }} />
        </B2CLayout>
    );
}
