import { axios } from '@/lib/axios';
import { SubscribeDeckResponse } from '../types';

type SubscribeDeckDTO = {
  channel_id: string
  channel_package_id: string
  device_os: string,
  source: string,
  discounted_amount: string,
  discount_coupon: string,
  final_amount_paid: string,
  utm: string
}

export const subscribeDeck = (data: SubscribeDeckDTO, token: string, headerParams:any): Promise<SubscribeDeckResponse> => {
  return axios.post(headerParams === 'opigo' ? '/channels/channels/subscribe' : headerParams === 'kapil' ? `/channels/channels/subscribe?source=web&subscription_source=${headerParams}` : '/channels/channels/subscribe?source=web', data, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  });
};
